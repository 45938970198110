.wrapper div {    
    padding: 0;
    margin: 0;
}

.wrapper:focus-within div {
    outline: none;
    display: block;
    padding: 0;
    margin: 0;
}